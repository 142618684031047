.navbar {
  //margin-left: $grid-gutter-width * 2;
  //margin-right: $grid-gutter-width * 2;
  margin-left: $grid-gutter-width;
  margin-right: $grid-gutter-width;
  z-index: 10;

  .navbar-nav {
    top: -14px;
    position: relative;

    /*li:hover>.dropdown-menu {
      display: block;
    }*/
  }

  .nav-item {
    margin-right: 0.5em;

    &.active {
      border-bottom: 1px solid $color-accent;
    }

    &.social a:hover {
      text-decoration: none;
    }
  }


  a {
    font-size: $font-size-medium;
    letter-spacing: 0.2em;
    color: $color-gunmetal;
    text-transform: uppercase;

    .la {
      font-size: $font-size-medium * 2;
      padding-top: $font-size-medium / 3;
      font-weight: normal;
    }

    &:hover {
      color: $primary;
    }
  }

  .dropdown-menu {
    background-color: $color-navy-blue;
    opacity: 1;

    a {
      color: white;

      &:hover {
        color: $primary;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-toggler {
    border: none;
    border-bottom: 1px solid $color-neutral;
    margin-bottom: 1em;
    display: block;
  }

  .navbar .nav-item.dropdown a.dropdown-toggle:after {
    border: none!important;
    content: 'ï¼';
    vertical-align: inherit;
    margin-left: 0;
  }

  .navbar .nav-item.dropdown.show a.dropdown-toggle:after {
    content: 'ï¼';
  }

  .navbar .dropdown-menu {
    background-color: white;
    border: none;

    a {
      color: rgba(0, 0, 0, .7);
    }
  }
}

body.has-hero .navbar .nav-link,
body.has-hero .navbar .nav-item a {
  color: white!important;

  i {
    color: white!important;
  }
}
