// pale gold #C0B283
// silk #DCD0C0
// paper #F4F4F4
// charcoal: #373737

$color-sky: #CAEBF2;
$color-carbon: #A9A9A9;
$color-watermelon: #FF3B3F;
$color-neutral: #EFEFEF;

$color-grain: #D7CEC7;
$color-blackboard: #565656;
$color-oxblood: #76323F;
$color-tan: #C09F80;

$color-navy-blue: #18121E;
$color-gunmetal: #233237;
$color-rusty-red: #984B43;
$color-warm-yellow: #EAC67A;
$color-verdant-green: #698C1C;

$color-accent: darken($color-warm-yellow, 20%);//#7da2a9;
$color-box: $color-gunmetal;
$color-box-background: none;
$color-text: white;
$primary: $color-oxblood;

$dropdown-border-radius: 0;
$btn-border-radius: 0;

$font-headlines: 'Cormorant Garamond', serif;
$font-text: 'Raleway', sans-serif;

$font-size-very-big: 24px;
$font-size-big: 20px;
$font-size-medium: 14px;
$font-size-small: 12px;

$font-path: "/build/fonts/";
@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}/line-awesome.3f85d803.eot?#iefix');
  src: url('#{$font-path}/line-awesome.3f85d803.eot?#iefix') format('eot'),
  url('#{$font-path}/line-awesome.452a5b42.woff2') format('woff2'),
  url('#{$font-path}/line-awesome.8b129059.woff') format('woff'),
  url('#{$font-path}/line-awesome.4d42f5f0.ttf') format('truetype');
}


@import "~bootstrap/scss/bootstrap";
@import "~line-awesome/dist/css/line-awesome-font-awesome.min.css";
@import "~line-awesome/dist/css/line-awesome.min.css";
@import "portfolio";
@import "nav";
@import "lightbox";
@import "content";
@import "blog";
@import "modules";

body
{
  /*background-color: $color-navy-blue;*/
  background-color: #fff;
  font-family: $font-text;
}

h1,h2,h3,h4,h5,h6
{
  font-family: $font-headlines;
}

h1 {
  font-size: $font-size-very-big * 3;
}

h1, h2{
  font-weight: 600;
  color: $color-gunmetal;
}

h2 {
  margin-bottom: 1em;
}

h3,h4,h5,h6 {
  font-weight: 400;
}

.asset {
  margin-bottom: 2em;
}

.spinner {
  text-align: center;
  display: none;

  .spinner-border {
    width: 10rem;
    height: 10rem;
    opacity: .3;
  }
}

.fadein {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@include media-breakpoint-down(sm) {
  main {
    padding: 0 1em;
  }
}
