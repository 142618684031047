.module {

  audio {
    width: 100%;
  }

  &.hero {
    width: 100%;
    height: 110vh;
    overflow: hidden;
    background-size: cover;
    position: relative;
    top: -25vh;
    margin-bottom: 0;

    section {
      position: absolute;
      bottom: 10vh;
      width: 100%;

      h1 {
        color: white;
        text-align: center;
      }

      .cta {
        background-color: white;
        color: $color-oxblood;
        font-weight: bold;

        &:first-of-type {
          margin-right: 50px;
        }
      }
    }
  }

  &.cta-module {
    margin-bottom: 0;
    margin-top: $spacer * -3;
  }

  margin-bottom: 3em;
  padding: 0;
  
  a {
    text-decoration: underline;
  }

  &.container-fluid.padded {
    padding: 0 3em;
  }

  .teaser {
    h2 {
      margin-top: 1.5em;

      &:first-child {
        margin-top: 0;
      }
    }

    figcaption {
      margin-top: 2em;
      font-style: italic;
    }
  }

  .art-wall {
    figure {
      //margin: 0;
    }
  }

  .big-text {
    font-size: 3em;
    line-height: 140%;
    font-family: $font-headlines;

    p {
      text-align: center;
    }

    strong {
      color: $primary;
    }
  }

  &.single-picture.full-size img {
    width: 100%;
  }

  .newsletter {
    background-color: $color-gunmetal;
    padding: 4em 8em;
    color: white;

    h1 {
      color: white;
    }

    a,
    a:visited,
    a:active,
    a:focus {
      color: white;
    }
  }

  .testimonial {
    background-color: $color-gunmetal;
    padding: 4em 8em;

    img {
      border-radius: 100px;
      position: relative;
      width: 200px;
      border: 8px solid #fff;
    }

    blockquote {
      color: white;
      font-size: $font-size-very-big * 1.2;
      padding-right: 5em;

      .blockquote-footer {
        margin-top: 3em;
        font-size: $font-size-big;

        strong {
          font-size: $font-size-very-big * 1.2;
        }

        &:before {
          content: "";
        }

        text-align: right;
        color: white;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .module {
    .big-text {
      font-size: 2em;
    }

    .testimonial {
      padding: 0.5em 0.5em;

      blockquote {
        padding-right: 0;
        font-size: $font-size-very-big;
      }
    }

    .newsletter {
      padding: 0.5em 0.5em;
    }
  }
}
