$column-count: 4;

.about-intro {
  margin-top: 0em;
  margin-bottom: 4em;
  color: $color-box;
  padding: 2em;

  h2 {
    font-size: $font-size-very-big;
    margin-bottom: 0.9em;
  }
  h3 {
    font-size: $font-size-big;
  }
}


.portfolio-container {
  padding: 0 3em;

  .portfolio {
    margin: 0 0 20em 0;

  }
}

.portfolio-container,
.featured-artwork {
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0px;
  }

  .column {
    flex: percentage(1 / $column-count);
    max-width: percentage(1 / $column-count);
    padding: 0 0px;
  }
}

.portfolio-container .column,
.featured-artwork .column,
.art-wall {
  figure {
    position: relative;
    overflow: hidden;
    margin: 0;

    a {
      cursor: zoom-in;
      display: block;
      text-decoration: none;

      .overlay, .overlay-background {
        display: none;
        position: absolute;
        left: 0;
      }

      .overlay {
        z-index: 5;
        padding: 15px;
        bottom: 0;
        width: 100%;

        h3 {
          color: $color-accent;
          text-align: center;
          width: 100%;
        }

        h4 {
          color: white;
          text-align: center;
          width: 100%;
          font-size: 1.15em;
        }

        h5 {
          margin-bottom: 10px;
        }
      }

      .overlay-background {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .75);
        z-index: 4;
        top: 0;
      }

      &:hover {
        .overlay, .overlay-background {
          display: block;
        }
      }
    }

    img {
      margin-top: 0px;

      vertical-align: middle;
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .portfolio-container,
  .featured-artwork {
    padding: 0 1.5em;

    .row {
      flex-direction: column;
    }
    .column {
      flex: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}
