.content-container {
  .preview {
    opacity: 0.6;
  }

  .blog-post-teaser {
    header {
      margin-bottom: 1.5em;

      h1 {
        margin-top: 1em;
        font-size: $font-size-big;
      }

      time {
        font-size: $font-size-small;
        text-transform: uppercase;
      }
    }
  }

  .blog-post {
    section {
      margin-top: 3em;

      a, a:visited, a:active, a:focus {
        color: $color-oxblood;
        font-weight: bold;

        &.btn {
          color: $white;
        }
      }

      a[href*="//"]:not([href*="joern.art"]) {
        &:after {
          font-family: LineAwesome;
          content: "\f1d3";
          padding-left: .3em;
        }
      }

      p {

        @include media-breakpoint-up(md) {
          img {
            left: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            margin-top: 2em;
            margin-bottom: 2em;
            max-width: 100vw;
            position: relative;
            right: 50%;
            width: 100vw;
            padding: 0 10em;
          }
        }
      }

      h2 {
        font-size: $font-size-big;
        margin-top: 2.5em;
        margin-bottom: 1.5em;
      }

      img {
        max-width: 100%;
        margin-bottom: 2em;
      }
    }
  }
}

.hero {
  width: 100%;
  text-align: center;
  border-top: 2px solid $color-text;
  border-bottom: 2px solid $color-text;
  color: $color-text;
  position: relative;
  min-height: 200px;
  overflow: hidden;

  .background {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;

    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);

    z-index: 1;
  }

  .hero-content {
    min-height: 200px;
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 2;
    padding: 3em 0;
    background: rgba(0,0,0,.4);

    h1 {
      color: white;
    }
  }
}
