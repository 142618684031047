
ul.list-group a.list-group-item.list-group-item-action {
  text-decoration: none;
}

.btn {
  margin-top: 2em;
  font-weight: bold;

  &.btn-primary {
    border-color: white;
  }

  &.cta {
    text-decoration: none;
    margin: 2em auto;
    padding: 0.6em 1em;
    color: white;
    /*border-radius: 40px;*/
    background: $primary;
    border: 1px solid $primary;
    box-shadow: darken($primary, 20%) 0px 5px 0px;

    &:hover {
      background: $primary;
      color: white;
      box-shadow: darken($primary, 20%) 0px 2px 0px;
    }
  }
}

.content-container, .module {
  padding: 0 2em;
  background-color: $color-box-background;
  color: $color-box;

  .row:first-child h1 {
    text-align: center;
  }

  hr {
    border: 0;
    height: 2px;
    width: 75%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), $color-accent, rgba(0, 0, 0, 0));
    margin-bottom: 5em;
  }

  .text-content {
    line-height: 170%;

    blockquote {
      font-size: 1.4em;
      padding: 2em 2em 2em 4em;
      background-color: $color-gunmetal;
      font-style: italic;
      line-height: 150%;
      position: relative;
      color: white;
    }

    blockquote:before {
      content: "\201C";
      color: $color-accent;
      font-size: 8em;
      position: absolute;
      left: 0;
      top: 50%;
      font-family: $font-headlines;
    }

    &.padded {
      padding: 0 10em;
    }

    strong {
      color: $color-accent;
    }

    p img {
      max-width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .module.container-fluid.padded {
    padding: 0!important;
  }

  .hero .hero-content {
    background: none!important;
  }
  .hero .background {
    background-image: none!important;
  }

  .content-container .text-content {
    padding: 0 0.5em;

    &.padded {
      padding: 0;
    }


    blockquote {
      padding: 3.5em 0.5em 0.5em 0.5em;
      font-size: 1em;

      &:before {
        left: 0%;
        top: 15%;
      }
    }
  }
}
